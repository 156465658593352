import React from 'react'
import { Stack, Text } from '@chakra-ui/react'

export const SubscriptionMessage = () => {
  return (
    <Stack spacing="4">
      <Text fontWeight="bold">Thank you for considering Sequence</Text>
      <Text fontSize="small">
        We're two passionate climbers with a tech background based in Australia. Together, we have
        over 35+ years of experience.
      </Text>
      <Text fontSize="small">
        Our mission is to empower you to become a better climber by making a world class training
        platform.
      </Text>

      <Text fontSize="small">
        Think of supporting Sequence as less of a subscription, and more of an investment — both for
        your climbing, and for the continued development of the platform.
      </Text>

      <Text fontSize="small">
        Cheers,
        <br />
        Jake & Deano
      </Text>
    </Stack>
  )
}

export default SubscriptionMessage
