import { useNavigate } from 'react-router-dom'
import { Button, Box, Heading, HStack, Icon, Image, Stack } from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa'

import logo from './assets/sequence-logo-white.png'
import videoWebM from './assets/onboarding-introduction.webm'
import videoMp4 from './assets/onboarding-introduction.mp4'
import posterImage from './assets/poster.png'

const Welcome = () => {
  const navigate = useNavigate()

  return (
    <Box height="100vh" bg="brand.500" py="12" overflow="scroll">
      <Box maxWidth="840px" width="100%" px="4" margin="auto">
        <Stack alignItems="center" spacing="8">
          <Image src={logo} alt="Logo" width="60px" height="auto" />
          <Heading
            textAlign="center"
            size="xl"
            color="white"
            fontWeight="thin"
            textTransform="uppercase"
            letterSpacing="widest"
          >
            Welcome to Sequence!
          </Heading>
        </Stack>

        <Box _hover={{ cursor: 'pointer' }} my="8">
          <video
            controls
            disablePictureInPicture={true}
            controlsList="nodownload noplaybackrate"
            poster={posterImage}
            style={{ objectFit: 'fill' }}
          >
            <source src={videoWebM} type="video/webm" />
            <source src={videoMp4} type="video/mp4" />
          </video>
        </Box>

        <HStack spacing="3" justifyContent="flex-end">
          <Button
            background="white"
            letterSpacing="wide"
            color="brand.500"
            _hover={{ background: 'brand.600', color: 'white' }}
            onClick={() => navigate('/dashboard')}
          >
            Let's get started <Icon fontWeight="semibold" boxSize={4} ml="2" as={FaArrowRight} />
          </Button>
        </HStack>
      </Box>
    </Box>
  )
}
export default Welcome
