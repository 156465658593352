import { Badge, Tooltip } from '@chakra-ui/react'

const AdminBanner = ({ impersonateData }) => {
  return (
    <Tooltip label={impersonateData.impersonatingUserEmail}>
      <Badge
        colorScheme="red"
        fontSize="16px"
        paddingTop={2}
        paddingBottom={1}
        paddingLeft={2}
        paddingRight={2}
        ml="4"
      >
        Impersonating — {impersonateData.impersonatingUserName}
      </Badge>
    </Tooltip>
  )
}

export default AdminBanner
