import React from 'react'
import { NavLink, Routes, Route } from 'react-router-dom'
import { Box, Container, Link, Text } from '@chakra-ui/react'

import { useQuery, useImpersonate } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

import Account from './Account'
import ChangePassword from './ChangePassword'
import DeleteAccount from './DeleteAccount'
import General from './General'
import Email from './Email'
import Coaching from './Coaching'
import Admin from './Admin'

const Settings = () => {
  const impersonateData = useImpersonate((state) => state.impersonateData)
  const { data, isLoading, hasError } = useQuery('/accounts/get_settings')

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const { showAdmin, isClient } = data

  const SettingsContainer = ({ children }) => {
    return (
      <Container maxW="container.lg" paddingY={{ base: 3, md: 10 }}>
        <Box display={{ base: 'block', md: 'flex' }}>{children}</Box>
      </Container>
    )
  }

  const MenuView = ({ children }) => {
    return (
      <Box
        width={{ base: 'auto', md: '300px' }}
        padding={{ base: 3, md: 10 }}
        backgroundColor="gray.50"
      >
        {children}
      </Box>
    )
  }

  const MenuHeader = () => {
    return (
      <Box mb="4">
        <Text
          color="gray.500"
          fontWeight={{ base: 'bold', md: 'light' }}
          fontSize={['base', 'base', 'base', 'base', '2xl']}
        >
          Settings
        </Text>
        <Text fontSize="small" display={{ base: 'none', md: 'block' }}>
          Manage your settings
        </Text>
      </Box>
    )
  }

  const MenuOptions = () => {
    return (
      <>
        {showAdmin && <SettingNavLink title="Admin" link="admin" />}
        {isClient && <SettingNavLink title="Coaching" link="coaching" />}

        <SettingNavLink title="General" link="" />
        {!impersonateData.impersonating && (
          <SettingNavLink title="Email Reports" link="email-reports" />
        )}
        {!impersonateData.impersonating && (
          <>
            <SettingNavLink title="Account" link="account" />
            <SettingNavLink title="Change Password" link="change-password" />
            <SettingNavLink title="Delete Account" link="delete-account" />
          </>
        )}
      </>
    )
  }

  const SettingNavLink = ({ title, link }) => {
    return (
      <Link
        as={NavLink}
        display="flex"
        mb={{ base: 1, md: 3 }}
        py={1}
        color="gray.500"
        _activeLink={{ color: 'brand.500' }}
        _focus={{ outline: 'none' }}
        _hover={{
          textDecoration: 'none',
          color: 'gray.600',
        }}
        to={link}
        end
      >
        {title}
      </Link>
    )
  }

  const ContentView = ({ children }) => {
    return (
      <Box
        mt={{ base: '4', md: '12' }}
        ml={{ base: '3', md: '6' }}
        px={{ base: '0', md: '6' }}
        width="100%"
        data-cy="settings-content"
      >
        {children}
      </Box>
    )
  }

  const SettingsRoutes = () => {
    return (
      <Routes>
        <Route path="/" exact element={<General />} />
        <Route path="account" element={<Account />} />
        <Route path="email-reports" element={<Email />} />
        <Route path="admin" element={<Admin />} />
        <Route path="coaching" element={<Coaching {...{ isClient }} />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="delete-account" element={<DeleteAccount />} />
      </Routes>
    )
  }

  return (
    <SettingsContainer>
      <MenuView>
        <MenuHeader />
        <MenuOptions />
      </MenuView>
      <ContentView>
        <SettingsRoutes />
      </ContentView>
    </SettingsContainer>
  )
}

export default Settings
