import React, { useContext, useRef } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { ChevronDown, Menu as MenuIcon, User as UserIcon } from 'react-feather'
import {
  Box,
  Button,
  Flex,
  Text,
  Image,
  Link,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  MenuItem,
  Portal,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

import { UserContext } from 'providers/UserProvider'

import { useImpersonate, useStateManager, useUserPreferences, useCoaching } from 'hooks'

import securedApi from 'backend/axios'

import AdminBanner from './AdminBanner'
import CoachingModeToggle from './CoachingModeToggle'
import CoachingNavigation from './CoachingNavigation'

import UpgradeButton from 'components/General/UpgradeButton'

import logo from 'assets/logo.png'
import stagingLogo from 'assets/logo-staging.png'
import localLogo from 'assets/logo-local.png'

const Navigation = () => {
  const { loggedInContext, planContext } = useContext(UserContext)

  const [user] = loggedInContext
  const [userPlan] = planContext

  const isCoachingInterfaceEnabled = useCoaching((state) => state.isCoachingInterfaceEnabled)

  const finalFocusRef = useRef()
  const { clearAll } = useStateManager()

  const isPurchaseLandingExternal = window.location.pathname.startsWith(
    '/purchase_landing_external/'
  )

  const hideNavLinks = isPurchaseLandingExternal

  const impersonateData = useImpersonate((state) => state.impersonateData)

  const isCoach = useUserPreferences((state) => state.isCoach)

  // component initial render is happening before context values are loaded
  // (then after they are loaded component is probably rerendering with available values)
  // (this is confirmed because isTrial = userPlan?.slice etc. is undefined initially)
  // => probably fine but could be tighter

  const navigate = useNavigate()

  const signOut = () => {
    if (!localStorage.signedIn) {
      return
    }

    securedApi
      .delete('/signin')
      .then(() => {
        clearAll()
        navigate('/')
      })
      .catch((error) => {
        console.log('Error signing out', error)
        clearAll()
        navigate('/')
      })
  }

  // only show upgrade button in header for trial accounts
  const isTrial = userPlan?.slice(0, 5) === 'Trial'
  const isPlanPurchase = userPlan?.slice(10, -1) === 'plan-purchase'
  const isCeasedCoachingAmnesty = userPlan?.includes('(ceased coaching client)')

  const displayUpgradeHeader = isTrial || isPlanPurchase || isCeasedCoachingAmnesty

  const { REACT_APP_SEQ_ENV } = process.env
  const envLogo =
    REACT_APP_SEQ_ENV === 'production'
      ? logo
      : REACT_APP_SEQ_ENV === 'staging'
      ? stagingLogo
      : REACT_APP_SEQ_ENV === 'development'
      ? localLogo
      : localLogo

  const MainNavigationLink = ({ to, title }) => {
    return (
      <Link
        fontSize={['base', 'base', 'base', 'base', '2xl']}
        as={NavLink}
        mr={['4', '4', '4', '6']}
        _activeLink={{ color: 'black' }}
        color="gray.500"
        fontWeight="light"
        _hover={{ textDecoration: 'none', color: 'brand.500' }}
        end
        {...{ to }}
      >
        {title}
      </Link>
    )
  }

  const SecondaryNavigationLink = ({ to, title, isExternal }) => {
    if (isExternal) {
      return (
        <Link
          fontSize="base"
          href={to}
          ml="6"
          color="gray.500"
          fontWeight="light"
          _hover={{ textDecoration: 'none', color: 'brand.500' }}
          target="blank"
          display={{ base: 'none', md: 'block' }}
        >
          {title}
        </Link>
      )
    }

    return (
      <Link
        fontSize="base"
        as={NavLink}
        ml="6"
        _activeLink={{ color: 'brand.500' }}
        color="gray.500"
        fontWeight="light"
        _hover={{ textDecoration: 'none', color: 'brand.500' }}
        {...{ to }}
      >
        {title}
      </Link>
    )
  }

  const renderMainNavigation = () => {
    const links = [
      {
        to: '/dashboard',
        title: 'Dashboard',
      },
      {
        to: '/planner',
        title: 'Planner',
      },
      {
        to: '/workouts',
        title: 'Workouts',
      },
      {
        to: '/plans',
        title: 'Plans',
      },
    ]

    return links.map(({ to, title }) => <MainNavigationLink key={title} {...{ to, title }} />)
  }

  const renderMainDropdownNavItem = () => {
    return (
      <Menu>
        <MenuButton
          as={Button}
          fontSize={['base', 'base', 'base', 'base', '2xl']}
          p="0"
          color="gray.500"
          fontWeight="light"
          background="none"
          _active={{ background: 'none' }}
          _focus={{ background: 'none' }}
          _hover={{
            background: 'none',
            textDecoration: 'none',
            color: 'brand.500',
          }}
          iconSpacing="1"
          rightIcon={<Icon as={ChevronDown} w={4} h={4} strokeWidth="1px" />}
        >
          Performance
        </MenuButton>
        <Portal>
          <MenuList zIndex="21">
            <MenuItem as={NavLink} to="/metrics" _activeLink={{ color: 'black' }} p="2">
              Metrics
            </MenuItem>
            <MenuItem as={NavLink} to="/goals" _activeLink={{ color: 'black' }} p="2">
              Goals
            </MenuItem>
            <MenuItem as={NavLink} to="/tests" _activeLink={{ color: 'black' }} p="2">
              Tests
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    )
  }

  const renderSecondaryNavigation = () => {
    const links = [
      {
        to: '/settings',
        title: 'Settings',
      },
    ]

    return links.map(({ to, href, title, isExternal }) => (
      <SecondaryNavigationLink key={title} {...{ to, href, title, isExternal }} />
    ))
  }

  const MenuLink = ({ to, title, isExternal }) => {
    if (isExternal) {
      return (
        <MenuItem as={Link} href={to} target="blank" _activeLink={{ color: 'black' }} p="2">
          {title} <ExternalLinkIcon ml={2} mb={1} />
        </MenuItem>
      )
    } else {
      return (
        <MenuItem as={NavLink} to={to} _activeLink={{ color: 'black' }} p="2">
          {title}
        </MenuItem>
      )
    }
  }

  const renderMainMobileNavigation = () => {
    const links = [
      {
        to: '/dashboard',
        title: 'Dashboard',
      },
      {
        to: '/planner',
        title: 'Planner',
      },
      {
        to: '/workouts',
        title: 'Workouts',
      },
      {
        to: '/plans',
        title: 'Plans',
      },
      {
        divider: true,
      },
      {
        to: '/metrics',
        title: 'Metrics',
      },
      {
        to: '/goals',
        title: 'Goals',
      },
      {
        to: '/tests',
        title: 'Tests',
      },
    ]

    return (
      <Menu>
        <MenuButton as={IconButton} aria-label="Navigation" icon={<MenuIcon />} variant="ghost" />
        <MenuList>
          {links.map(({ to, title, divider }, idx) => {
            if (divider) {
              return <MenuDivider key={idx} />
            } else {
              return <MenuLink key={idx} to={to} title={title} p="2" />
            }
          })}
        </MenuList>
      </Menu>
    )
  }

  const renderSecondaryMobileNavigation = () => {
    const links = [
      {
        to: '/settings',
        title: 'Settings',
      },
      {
        divider: true,
      },
    ]

    return (
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="User settings"
          icon={<UserIcon />}
          variant="ghost"
        />
        <MenuList>
          {links.map(({ to, title, isExternal, divider }, idx) => {
            if (divider) {
              return <MenuDivider key={idx} />
            } else {
              return <MenuLink key={idx} to={to} title={title} isExternal={isExternal} p="2" />
            }
          })}
          <MenuItem onClick={signOut} p="2">
            Sign Out
          </MenuItem>
        </MenuList>
      </Menu>
    )
  }

  const renderSignInSignUp = () => {
    const links = [
      {
        to: '/sign-in',
        title: 'Sign In',
      },
      {
        to: '/sign-up',
        title: 'Sign Up',
      },
    ]

    return links.map(({ to, title }) => <SecondaryNavigationLink key={title} {...{ to, title }} />)
  }

  const NavLinkSignOut = () => {
    return (
      <Button
        onClick={signOut}
        ml="6"
        color="gray.500"
        fontWeight="light"
        variant="link"
        _hover={{ textDecoration: 'none', color: 'brand.500' }}
      >
        Sign Out
      </Button>
    )
  }

  const logoURL = () => {
    if (!user) {
      return '/'
    }

    if (isCoachingInterfaceEnabled) {
      return '/coaching'
    }

    return '/dashboard'
  }

  return (
    <Box
      position="sticky"
      top="0"
      width="100%"
      zIndex="20"
      background="gray.50"
      overflowX={{ md: 'visible', sm: 'scroll' }}
      ref={finalFocusRef}
    >
      <Box px="6" borderBottom="1px" borderColor="gray.300">
        <Box
          minHeight="60px"
          alignItems="center"
          display={{ base: 'flex', sm: 'none' }}
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box>{user && renderMainMobileNavigation()}</Box>
          <Box alignItems="center">
            <Link as={NavLink} to={logoURL()} display={{ md: 'none', lg: 'block' }}>
              <Image src={envLogo} alt="Logo" width="30px" height="auto" />
            </Link>
          </Box>
          <Box>{user && renderSecondaryMobileNavigation()}</Box>
        </Box>
        <Box minHeight="75px" alignItems="center" display={{ base: 'none', sm: 'flex' }}>
          <Box display="flex" flexGrow="1">
            <Link as={NavLink} to={logoURL()} display={{ md: 'none', lg: 'block' }}>
              <Box alignItems="center" display={{ base: 'none', md: 'flex' }}>
                <Image src={envLogo} alt="Logo" width="30px" height="auto" />
                <Text
                  fontSize="sm"
                  pt="1"
                  pl="2"
                  pr="2"
                  color="gray.700"
                  textTransform="uppercase"
                  fontWeight="semibold"
                  display={{ base: 'none', lg: 'block' }}
                >
                  Sequence
                </Text>
              </Box>
            </Link>

            <Box display="flex" alignItems="center" ml={{ md: '0', lg: '10' }}>
              {user && isCoachingInterfaceEnabled && <CoachingNavigation />}

              {user && !isCoachingInterfaceEnabled && (
                <>
                  {renderMainNavigation()}
                  {renderMainDropdownNavItem()}
                </>
              )}
            </Box>
          </Box>
          <Box display="flex">
            <Box display="flex" alignItems="center" justifyContent="end">
              {!hideNavLinks &&
                (user ? (
                  <>
                    {isCoach && (
                      <Flex alignItems="center">
                        <Box ml="8" mr="0">
                          <CoachingModeToggle />
                        </Box>
                      </Flex>
                    )}
                    {impersonateData.impersonateAsAdmin && <AdminBanner {...{ impersonateData }} />}

                    {displayUpgradeHeader && (
                      <Box ml="6">
                        <UpgradeButton />
                      </Box>
                    )}
                    {!isCoachingInterfaceEnabled && renderSecondaryNavigation()}
                    <NavLinkSignOut />
                  </>
                ) : (
                  renderSignInSignUp()
                ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Navigation
