import { NavLink } from 'react-router-dom'
import {
  Box,
  HStack,
  Link,
  Divider,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
} from '@chakra-ui/react'
import { ChevronDown } from 'react-feather'

import { useImpersonate } from 'hooks'

import ClientSelect from 'domain/Coaching/Components/ClientSelect'

const NavItem = ({ path, title }) => (
  <Link
    as={NavLink}
    _activeLink={{ color: 'brand.500' }}
    color="gray.500"
    fontWeight="light"
    _hover={{ textDecoration: 'none', color: 'brand.500' }}
    to={path}
    end
  >
    {title}
  </Link>
)

const CoachingNavigation = () => {
  const impersonateData = useImpersonate((state) => state.impersonateData)
  return (
    <>
      <HStack spacing={{ base: '4', md: '8' }}>
        <NavItem path="/coaching" title="Dashboard" />
        <NavItem path="/coaching/clients" title="Clients" />
        <NavItem path="/coaching/workouts" title="Coaching Workouts" />
        <Box height="40px">
          <Divider orientation="vertical" background="gray.400" width="1px" />
        </Box>

        <ClientSelect {...{ impersonateData }} />

        {impersonateData.impersonating && (
          <>
            <NavItem path="/planner" title="Planner" />
            <NavItem path="/workouts" title="Workouts" />

            <Menu>
              <MenuButton
                as={Button}
                p="0"
                color="gray.500"
                fontWeight="light"
                background="none"
                _active={{ background: 'none' }}
                _focus={{ background: 'none' }}
                _hover={{
                  background: 'none',
                  textDecoration: 'none',
                  color: 'brand.500',
                }}
                iconSpacing="1"
                rightIcon={<Icon as={ChevronDown} w={4} h={4} strokeWidth="1px" />}
              >
                Performance
              </MenuButton>
              <Portal>
                <MenuList zIndex="21">
                  <MenuItem as={NavLink} to="/metrics" _activeLink={{ color: 'black' }} p="2">
                    Metrics
                  </MenuItem>
                  <MenuItem as={NavLink} to="/goals" _activeLink={{ color: 'black' }} p="2">
                    Goals
                  </MenuItem>
                  <MenuItem as={NavLink} to="/tests" _activeLink={{ color: 'black' }} p="2">
                    Tests
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          </>
        )}
      </HStack>
    </>
  )
}

export default CoachingNavigation
