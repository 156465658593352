import { Badge } from '@chakra-ui/react'

import { useCoaching, useImpersonate } from 'hooks'

import securedApi from 'backend/axios'

const BadgeButton = ({ onClick, label }) => (
  <Badge variant="outline" onClick={onClick} _hover={{ cursor: 'pointer' }}>
    {label}
  </Badge>
)

const CoachingModeToggle = () => {
  const isCoachingInterfaceEnabled = useCoaching((state) => state.isCoachingInterfaceEnabled)
  const setCoachingInterfaceEnabled = useCoaching((state) => state.setCoachingInterfaceEnabled)
  const setCoachingInterfaceDisabled = useCoaching((state) => state.setCoachingInterfaceDisabled)
  const resetImpersonateData = useImpersonate((state) => state.resetImpersonateData)

  const handleTurnCoachingModeOn = () => {
    window.location.assign('/coaching')
    setCoachingInterfaceEnabled()
  }

  const handleTurnCoachingModeOff = () => {
    securedApi
      .post(`/impersonate/stop`)
      .then(() => {
        setCoachingInterfaceDisabled()
        resetImpersonateData()
        window.location.assign('/dashboard')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return !isCoachingInterfaceEnabled ? (
    <BadgeButton onClick={() => handleTurnCoachingModeOn()} label="Enter Coaching" />
  ) : (
    <BadgeButton onClick={() => handleTurnCoachingModeOff()} label="Exit Coaching" />
  )
}

export default CoachingModeToggle
