import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { Box } from '@chakra-ui/react'

import { usePlannerMethods } from './hooks'

const DraggableNote = ({ day, data }) => {
  const { renderDraggableNote } = usePlannerMethods(data)

  return renderDraggableNote(day)
}

const DroppableNote = ({ index, day, data }) => {
  return (
    <Droppable droppableId={`note-${index.toString()}`} key={day.toString()} type="NOTE">
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          style={{
            // backgroundColor: snapshot.isDraggingOver ? 'blue' : 'yellow',
            backgroundColor: snapshot.isDraggingOver ? '' : '',
          }}
          {...provided.droppableProps}
        >
          <DraggableNote {...{ day, data }} />
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  )
}

export default DroppableNote
