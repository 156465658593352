import React from 'react'
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react'

import DailyMetricsContent from './DailyMetricsContent'
import DailyMetricsModalHeader from './DailyMetricsModalHeader'

const DailyMetricsModal = ({
  date,
  open,
  onClose,
  highlightAttributeId,
  highlightMenstrualCycle,
}) => {
  return (
    <Modal isOpen={open} scrollBehavior="inside" size="sm" {...{ onClose }}>
      <ModalOverlay />
      <ModalContent overflow="scroll" pb="2">
        <DailyMetricsModalHeader {...{ date }} />
        <ModalBody pb="4">
          <DailyMetricsContent
            {...{ date, highlightAttributeId, highlightMenstrualCycle, onClose }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default DailyMetricsModal
