import React from 'react'
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react'

import { usePlannerMethods } from 'domain/Planner/hooks'

import { useDailyMetrics } from './hooks'
import DailyMetricsContent from './DailyMetricsContent'
import DailyMetricsModalHeader from './DailyMetricsModalHeader'

const DailyMetricsPlannerModal = () => {
  const dailyMetricsVisible = useDailyMetrics((state) => state.dailyMetricsVisible)
  const dailyMetricsDate = useDailyMetrics((state) => state.dailyMetricsDate)
  const setDailyMetricsVisible = useDailyMetrics((state) => state.setDailyMetricsVisible)
  const { refreshData } = usePlannerMethods()

  const handleRefreshPlannerData = () => {
    setDailyMetricsVisible()
    refreshData()
  }

  const handleClose = async () => {
    setDailyMetricsVisible()
    refreshData()
  }

  return (
    <Modal isOpen={dailyMetricsVisible} onClose={handleClose} scrollBehavior="inside" size="sm">
      <ModalOverlay />
      <ModalContent overflow="scroll" pb="2">
        <DailyMetricsModalHeader onNavigate={handleRefreshPlannerData} date={dailyMetricsDate} />
        <ModalBody pb="4">
          <DailyMetricsContent date={dailyMetricsDate} onClose={handleClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default DailyMetricsPlannerModal
