import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { Alert, Box, Center, Container, Flex, Link, Stack, Text } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

import securedApi from 'backend/axios'
import { tzSafeDateFormat } from 'helpers/utils'
import { useStateManager, useQuery } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'
import { FormHeading } from 'components/Forms'
import Notify from 'components/Notification'

import { PasswordFields, SignUpButton } from 'domain/Auth/Elements'

const schema = z
  .object({
    password: z.string().min(6, { message: 'Required - must have at least 6 characters' }),
    password_confirmation: z.string().min(6, { message: 'Required' }),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "Passwords don't match",
    path: ['password_confirmation'],
  })

const PurchaseLandingExternal = () => {
  const navigate = useNavigate()

  const { id } = useParams()

  const query = `api/v1/purchases/landing_external?cs_id=${id}`
  const { data, isLoading, hasError } = useQuery(query)
  const [loading, setLoading] = useState(false)
  const { clearAll, setSignin, setAccountStatus } = useStateManager()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  })

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const { used, is_new_user, bonus, user, purchases } = data
  const purchase = purchases[0]
  const formattedDate = user.next_payment_date
    ? tzSafeDateFormat(user.next_payment_date, 'd MMMM yyyy')
    : null

  const onSubmit = ({ password, password_confirmation }) => {
    setLoading(true)
    securedApi
      .post('/signup_plan', {
        signup: {
          id: user.id,
          password,
          password_confirmation,
        },
      })
      .then((response) => {
        signUpSuccessful(response)
      })
      .catch((error) => {
        signUpFailed(error)
      })
  }

  const signUpSuccessful = (response) => {
    if (!response.data.csrf) {
      signUpFailed(response)
      return
    }

    setSignin(response.data)
    setAccountStatus(response.data.user)

    const { id, name, email, plan } = response.data.user

    window.analytics.identify(id, { name, email, plan })

    navigate('/welcome-plan')
  }

  const signUpFailed = (error) => {
    const errorContent = error.response?.data?.error || 'Error signing up. Please try again.'
    Notify({
      content: errorContent,
      type: 'error',
    })

    setLoading(false)

    clearAll()
  }

  const MainFlex = ({ children }) => {
    return (
      <Flex
        width="100%"
        py="10"
        px="4"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        {children}
      </Flex>
    )
  }

  if (used) {
    return (
      <Container py={{ base: '4', md: '6' }} maxWidth="container.lg">
        <Text textAlign="center">
          This link has already been used to sign up after purchasing a plan.
        </Text>
        <Text textAlign="center">
          Please{' '}
          <Link as={NavLink} color="brand.500" fontWeight="bold" to={'/sign-in'}>
            sign in
          </Link>{' '}
          or{' '}
          <Link
            color="brand.500"
            fontWeight="bold"
            href="mailto:help@sequence-app.com?subject=Question%20Training%20Plans"
          >
            contact support
          </Link>{' '}
          if you require additional assistance.
        </Text>
      </Container>
    )
  }

  return (
    <MainFlex>
      <Container py={{ base: '4', md: '6' }} maxWidth="container.lg">
        <Stack spacing="3" alignItems="center">
          <Text textAlign="center" fontSize="2xl">
            Purchase Successful
          </Text>

          <Box boxShadow="md" p="6" rounded="md" bg="white" maxW="lg">
            <Stack spacing="3">
              <Box textAlign="center">
                <Text fontSize="lg" fontWeight="bold">
                  {purchase.stripe_product_name}
                </Text>
                <Text>{purchase.stripe_product_description}</Text>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Container>

      {bonus && (
        <Container py={{ base: '4', md: '6' }} maxWidth="container.lg" alignItems="center">
          <Center>
            <Alert maxW="lg">
              <Stack>
                <Text textAlign="center">
                  You've been given a{' '}
                  <Text as="span" fontWeight="bold">
                    bonus 3 month subscription
                  </Text>{' '}
                  to Sequence with this training plan!
                </Text>
                <Text textAlign="center">
                  This will end on{' '}
                  <Text as="span" fontWeight="bold">
                    {formattedDate}
                  </Text>
                  .
                </Text>
              </Stack>
            </Alert>
          </Center>
        </Container>
      )}

      {is_new_user ? (
        <Box maxWidth="400px" width="100%">
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormHeading>
              <Text>
                Complete your sign up by entering a password, then we'll get you started with your
                new training plan!
              </Text>
            </FormHeading>
            <Stack mt="8">
              <PasswordFields {...{ register, errors }} />
              <SignUpButton {...{ loading }} />
            </Stack>
          </form>
        </Box>
      ) : (
        <Box maxWidth="400px" width="100%">
          <Text>
            Head over to your{' '}
            <Link
              as={NavLink}
              _activeLink={{ color: 'black' }}
              color="gray.500"
              fontWeight="light"
              _hover={{ textDecoration: 'none', color: 'brand.500' }}
              end
              to={'/plans/purchased-plans'}
            >
              plans section
            </Link>{' '}
            to apply your new plan 💪
          </Text>
        </Box>
      )}
    </MainFlex>
  )
}

export default PurchaseLandingExternal
