import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'

import { useQuery } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

const Coaching = ({ isCoach, isClient }) => {
  const {
    data: coachData = [],
    isLoading: isCoachesLoading,
    hasError: hasErrorCoach,
  } = useQuery('/api/v1/coaching/coaches')

  if (hasErrorCoach) return <Error />

  if (isCoachesLoading) {
    return <LoadingSpinner />
  }

  const hasCoaches = coachData?.length > 0

  return (
    <Flex flexDirection="column">
      {isClient && (
        <Box marginBottom={4}>
          <Text fontWeight="bold">Your coach:</Text>
          {hasCoaches ? (
            coachData.map((coach) => {
              return (
                <Text key={coach.email}>
                  {coach.name} ({coach.email})
                </Text>
              )
            })
          ) : (
            <Text>None</Text>
          )}
        </Box>
      )}
    </Flex>
  )
}

export default Coaching
