import React from 'react'
import { Box, Select, Button, Flex } from '@chakra-ui/react'
import { User } from 'react-feather'

import securedApi from 'backend/axios'

import { useImpersonate } from 'hooks'

const Impersonate = ({ users, admin = false, callback }) => {
  const impersonateData = useImpersonate((state) => state.impersonateData)
  const resetImpersonateData = useImpersonate((state) => state.resetImpersonateData)

  const { impersonateUserId, impersonating } = impersonateData

  const userDisplay = ({ name, email }) => {
    if (admin) {
      return `${name} - (${email})`
    }

    return `${name}`
  }

  const UserPicker = ({ users, unselectLabel, ...props }) => {
    return (
      <Select {...props} icon={!impersonating && <User />} variant="outline">
        {unselectLabel && <option key="unset">{unselectLabel}</option>}
        {users.map((user) => (
          <option key={user.client_id} value={user.client_id}>
            {userDisplay({ name: user.name, email: user.email })}
          </option>
        ))}
      </Select>
    )
  }

  const handleStopImpersonating = () => {
    securedApi
      .post(`/impersonate/stop`)
      .then(() => {
        resetImpersonateData()
        callback(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const changeImpersonateUserId = async (event) => {
    const { value } = event.currentTarget

    if (value === impersonateUserId) {
      return
    }

    if (value === 'None') {
      await securedApi
        .post(`/impersonate/stop`)
        .then(() => {
          resetImpersonateData()
          callback(false)
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      await securedApi
        .post(`/impersonate/start?impersonate_user_id=${value}&as_admin=${admin}`)
        .then(({ data }) => {
          const item = users.find((user) => user.client_id === value)

          useImpersonate.setState({
            impersonateData: {
              ...impersonateData,
              impersonating: true,
              impersonateAsAdmin: data.as_admin,
              impersonateUserId: value,
              impersonatingUserName: item.name,
              impersonatingUserEmail: item.email,
              coachingConnectionId: data.coaching_connection_id,
            },
          })

          callback(true)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <Box>
      <Flex>
        <Box flex="1">
          <UserPicker
            users={users}
            unselectLabel="None"
            defaultValue={impersonateUserId}
            onChange={changeImpersonateUserId}
          />
        </Box>
        {impersonating && (
          <Button variant="outline" ml="2" onClick={handleStopImpersonating} color="gray.500">
            Cancel
          </Button>
        )}
      </Flex>
    </Box>
  )
}

export default Impersonate
