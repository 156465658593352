import React from 'react'
import {
  Box,
  Card,
  CardBody,
  Flex,
  HStack,
  Spacer,
  Text,
  Stat,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react'

import HeaderDivider from 'components/General/HeaderDivider'

const ClientInfo = ({ title, data }) => {
  if (!data.length) {
    return null
  }

  return (
    <Card>
      <CardBody>
        <Stat>
          <StatLabel>{title}</StatLabel>
          <StatNumber color="gray.600">{data.length}</StatNumber>
        </Stat>
      </CardBody>
    </Card>
  )
}

const BusinessStats = ({ pendingClients, invitedClients, activeClients, inactiveClients }) => {
  const calculateCost = activeClients.length * 5
  return (
    <Box>
      <HeaderDivider heading="Business Stats" />
      <Flex>
        <Spacer />
        <HStack>
          <ClientInfo title="Pending Clients" data={pendingClients} />
          <ClientInfo title="Invited Clients" data={invitedClients} />
          <ClientInfo title="Active Clients" data={activeClients} />
          <ClientInfo title="Inactive Clients" data={inactiveClients} />
        </HStack>
      </Flex>
      <Flex>
        <Spacer />
        <Text mt="2" fontSize="sm">
          Total Cost: ${calculateCost} per month
        </Text>
      </Flex>
    </Box>
  )
}

export default BusinessStats
