import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { Box } from '@chakra-ui/react'

import { UserContext } from 'providers/UserProvider'

import Navigation from 'domain/Navigation'

import {
  SETTINGS_PAGE,
  SUBSCRIPTION_CANCELLED_PAGE,
  SUBSCRIPTION_CREATE_PAGE,
} from 'helpers/constants'

const TOKEN = 'csrf'

export const ViewWrapper = ({ children, hideNavigation }) => {
  const location = useLocation()
  const { validContext } = useContext(UserContext)
  const [userValid] = validContext

  // must explicitly test for false (to ignore undefined and null)
  const showExpiredOverlay = userValid === false
  const onSettingsPage = location.pathname.includes(SETTINGS_PAGE)
  const onSubscriptionCancelledPage = location.pathname.includes(SUBSCRIPTION_CANCELLED_PAGE)
  const onSubscriptionCreatePage = location.pathname.includes(SUBSCRIPTION_CREATE_PAGE)
  const applyOpacity =
    showExpiredOverlay &&
    !onSettingsPage &&
    !onSubscriptionCancelledPage &&
    !onSubscriptionCreatePage

  if (hideNavigation) return children

  return (
    <Box display="flex" flexDirection="column" minHeight="100%" opacity={applyOpacity ? 0.4 : 1}>
      {children}
    </Box>
  )
}

export const checkAuth = () => {
  if (localStorage.getItem(TOKEN) && localStorage.signedIn) {
    return true
  }
  return false
}

export const PrivateRouteWrapper = ({ children, hideNavigation }) => {
  const location = useLocation()
  const validSignin = checkAuth()

  if (validSignin) {
    return (
      <ViewWrapper {...{ hideNavigation }}>
        {!hideNavigation && <Navigation />}
        <Box display="flex" flexDirection="column" flexGrow="1">
          {children}
        </Box>
      </ViewWrapper>
    )
  } else {
    return (
      <Navigate
        to={{
          pathname: '/sign-in',
          state: { from: location },
        }}
      />
    )
  }
}

export const PublicRouteWrapper = ({ children, hideNavigation }) => {
  return (
    <ViewWrapper {...{ hideNavigation }}>
      {!hideNavigation && <Navigation />}
      <Box display="flex" flexDirection="column" flexGrow="1">
        {children}
      </Box>
    </ViewWrapper>
  )
}
